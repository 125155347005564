<template>
  <div class="fd-layout">
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.fd-layout {
  @include flex(row, flex-start, flex-start);
  position: relative;
}
</style>
